<template>
  <div
    style="height: 70vh;padding: 5px;gap:16px; overflow: scroll;"
    class="elevation-2 d-flex justify-space-around align-center"
  >
    <!-- Dialog para salvar a meta -->
    <v-dialog v-model="dialogSalvarMeta" persistent width="20vw">
      <v-card>
        <v-card-title>Gravação de meta</v-card-title>
        <v-card-text>
          <p>Deseja realmente gravar a meta para o trimestre?</p>
        </v-card-text>
        <v-card-actions class="d-flex flex-row">
          <v-btn class="error" @click="dialogSalvarMeta = false" x-small
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="salvaMeta" x-small>Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog para Remover a meta -->
    <v-dialog v-model="dialogRemoveMeta" persistent width="20vw">
      <v-card>
        <v-card-title>Exclusão de meta</v-card-title>
        <v-card-text>
          <p>Deseja realmente remover a meta para o trimestre?</p>
        </v-card-text>
        <v-card-actions class="d-flex flex-row">
          <v-btn class="error" @click="dialogRemoveMeta = false" x-small
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="removeMeta" x-small>Remover</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Tabela de Fauramento-->
    <div>
      <v-data-table
        :headers="headersFaturamento"
        dense
        :items="tabFaturamento"
        :items-per-page="tabFaturamento.length"
        class="elevation-1 tbmetasOpimed"
        no-data-text="Sem dados..."
        fixed-header
        hide-default-footer
        ><template v-slot:top>
          <div class="d-flex justify-center aling-center title">
            Faturamento Fonoaudiólogo
          </div>
        </template>
        <template v-slot:[`body.append`]="{ headers }">
          <tr class="blue--text text--darken-3">
            <th
              v-for="(item, index) in headers"
              :key="index"
              v-bind:class="[item.align1]"
            >
              <span class="caption" v-if="item.text === 'Mês'">Total</span>
              <span class="d-flex justify-end">{{ sumField(item.text) }}</span>
            </th>
          </tr>
          <tr class="blue--text text--darken-3">
            <th
              v-for="(item, index) in headers"
              :key="index"
              v-bind:class="[item.align1]"
            >
              <span class="caption" v-if="item.text === 'Mês'">Média</span>
              <span class="d-flex justify-end">{{
                mediaField(item.text)
              }}</span>
            </th>
          </tr>
        </template></v-data-table
      >
    </div>

    <!-- Espelho de Cálculo -->
    <div class="d-flex flex-column align-center" style="width: 25vw;">
      <div>
        <v-radio-group v-model="fonoEscolhido" row @change="calculaFormula">
          <v-radio
            v-for="(item, index) of dadosFonos"
            :key="index"
            :label="item.title"
            :value="item"
          ></v-radio>
        </v-radio-group>
      </div>
      <div v-if="fonoEscolhido">
        <span class="title">Cálculo da Meta</span>
        <div class="d-flex justify-center align-center">
          <!-- <v-col cols="6">
            <v-text-field
              v-model="indRejuste"
              dense
              hide-details
              label="Índice de reajuste"
              outlined
              @blur="calculaFormula()"
              :readonly="travaCampos"
              :class="travaCampos ? 'inputCalcEspelho' : ''"
            ></v-text-field>
          </v-col> -->
          <v-col cols="6">
            <v-text-field
              v-model="reajusteAvulso"
              dense
              hide-details
              outlined
              label="Reajuste avulso"
              @blur="calculaFormula()"
              :readonly="travaCampos"
              :class="travaCampos ? 'inputCalcEspelho' : ''"
            ></v-text-field>
          </v-col>
        </div>
        <div v-if="calcCompleto">
          <!-- <div class="d-flex justify-center" style="gap: 32px;"> -->
          <!-- <div
              class="d-flex flex-row justify-space-between align-start pa-2"
              style="border: 1px solid #BDBDBD; border-radius: 5px;"
            >
              <div>
                <span class="caption">Trimeste anteriror</span>
                <div
                  v-for="(item, index) in valoresTrimestreAnterior"
                  :key="index"
                  class="d-flex justify-space-between align-start "
                >
                  <span class="caption bold mr-3">{{ item.mes }}</span>
                  <span class="caption bold">{{ item.valor }}</span>
                </div>
              </div>
            </div> -->
          <!-- <div
              class="d-flex flex-row justify-space-between align-start pa-2"
              style="border: 1px solid #BDBDBD; border-radius: 5px;"
            >
              <div>
                <span class="caption">Últimos seis meses</span>
                <div
                  v-for="(item, index) in valoresSeis"
                  :key="index"
                  class="d-flex justify-space-between align-start"
                >
                  <span class="caption bold mr-3">{{ item.mes }}</span>
                  <span class="caption bold">{{ item.valor }}</span>
                </div>
              </div>
            </div> -->
          <!-- </div> -->
          <div class="d-flex flex-column mt-3" style="gap:8px;">
            <div>
              Valor da Meta Unidade de Negócio =
              {{ espelhoCalc[0] }}
            </div>
            <div>Valor da Meta Assistentes = {{ espelhoCalc[1] }}</div>
            <div>
              Reajuste avulso =
              <strong class="blue--text text--darken-4">{{
                espelhoCalc[2]
              }}</strong>
            </div>
            <div>
              Total (Unidade - Assistente) + Reajuste Avulso =
              <strong>{{ espelhoCalc[3] }}</strong>
            </div>
            <div>
              % da meta =
              <strong>{{ espelhoCalc[4] }}</strong>
            </div>
            <div>
              Pessoas =
              <strong>{{ espelhoCalc[6] }}</strong>
            </div>
            <div>
              Meta (% da Meta) / pessoas =
              <strong class="green--text text--darken-4">{{
                espelhoCalc[5]
              }}</strong>
            </div>
          </div>
        </div>
        <div class="d-flex justify-space-around align-center">
          <v-btn
            class="error mt-4"
            x-small
            :disabled="!travaCampos"
            @click="dialogRemoveMeta = true"
            >Remover Meta</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="success mt-4"
            x-small
            :disabled="travaCampos"
            @click="dialogSalvarMeta = true"
            >Salvar Meta</v-btn
          >
        </div>
      </div>
    </div>

    <!-- Meta -->
    <div class="d-flex flex-column align-center" style="width: 18vw;">
      <div class="d-flex flex-row justify-space-between pa-1">
        <span
          ><strong>{{ fonoEscolhido ? fonoEscolhido.title : "" }}</strong></span
        >
      </div>
      <div
        class="d-flex flex-row justify-space-between pa-1"
        style="border: 1px solid #BDBDBD; width: 300px; background-color: #1976D2; color:white;"
      >
        <span>Mês</span>
        <span>Meta</span>
      </div>
      <div v-for="(item, index) in resultTrimestre" :key="index">
        <div
          class="d-flex flex-row justify-space-between align-center pa-1"
          v-for="(itens, i) in metas.filter(m => m.trimestre === index + 1)"
          :key="i"
          style="border: 1px solid #BDBDBD; width: 300px;"
        >
          <span class="caption" style="width: 70px;">{{ itens.mes }}</span>
          <span
            v-if="itens.metaCurr > 0"
            class="caption"
            style="display: flex; justify-content: end;align-items: end;width: 100px;"
            >{{ itens.meta }}</span
          >
          <span
            v-else
            class="caption"
            style="display: flex; justify-content: end;align-items: end;width: 100px;"
            >-</span
          >
          <span
            class="caption"
            style="display: flex; justify-content: end;align-items: end;width: 60px;"
            >{{ itens.percent }} %</span
          >
        </div>
        <div
          v-if="item.valorCurr > 0"
          class="d-flex flex-row justify-space-between pa-1"
          style="border: 1px solid #BDBDBD; width: 300px; background-color: #BBDEFB;"
        >
          <span>{{ item.title }}</span>
          <span>{{ item.valor }}</span>
          <span>{{ item.percent }} %</span>
        </div>
        <div
          v-else
          class="d-flex flex-row justify-space-between pa-1"
          style="border: 1px solid #BDBDBD; width: 300px; background-color: #BBDEFB;"
        >
          <span>{{ item.title }}</span>
          <span>-</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { stringToBrMoney, stringToFloat } from "@/utils/stringParse";
import axios from "axios";
import { add, sub } from "date-fns";
import { mapState } from "vuex";
export default {
  name: "calcAssistentes",
  computed: {
    ...mapState({
      tabFaturamento: state => state.metasOpimed.tabFaturamento,
      headersFaturamento: state => state.metasOpimed.headersFaturamento,
      indiceReajuste: state => state.metasOpimed.indiceReajuste,
      ajustes: state => state.metasOpimed.ajustes,
      tabMetas: state => state.metasOpimed.tabMetas,
      trimestre: state => state.metasOpimed.trimestre,
      dadosCalculo: state => state.metasOpimed.dadosCalculo,
      assistentes: state => state.metasOpimed.assistentes,
      anoEscolhido: state => state.metasOpimed.anoEscolhido,
    }),
  },
  data: () => {
    return {
      valoresBase: [],
      dialogRemoveMeta: false,
      dialogSalvarMeta: false,
      travaCampos: false,
      dialogAguarde: false,
      indiceBanco: [],
      reajusteAvulso: null,
      indRejuste: null,
      title: "Unidade de negócio",
      espelhoCalc: [],
      calcCompleto: false,
      valoresSeis: [],
      valoresTrimestreAnterior: [],
      somaSeis: 0,
      somaTrimestreAnterior: 0,
      metas: [
        { mes: "Janeiro", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Fevereiro", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Março", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Abril", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Maio", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Junho", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Julho", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Agosto", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Setembro", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Outubro", meta: 0, percent: 0, trimestre: 4 },
        { mes: "Novembro", meta: 0, percent: 0, trimestre: 4 },
        { mes: "Dezembro", meta: 0, percent: 0, trimestre: 4 },
      ],
      resultTrimestre: [
        { title: "1 Trimestre", valor: 0, percent: 0, trimestre: 1 },
        { title: "2 Trimestre", valor: 0, percent: 0, trimestre: 2 },
        { title: "3 Trimestre", valor: 0, percent: 0, trimestre: 3 },
        { title: "4 Trimestre", valor: 0, percent: 0, trimestre: 4 },
      ],
      dadosFonos: [],
      fonoEscolhido: null,
    };
  },
  methods: {
    async removeMeta() {
      const dadosRemove = {
        D_E_L_E_T_: "*",
        R_E_C_D_E_L_: this.indiceBanco[0].R_E_C_N_O_,
        R_E_C_N_O_: this.indiceBanco[0].R_E_C_N_O_,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/metas`;

      await axios
        .put(url, dadosRemove)
        .then(res => {
          this.$toast.success(res.data, {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.dialogRemoveMeta = false;
          this.buscaIndice();
        })
        .catch(err => {
          console.log(err.response);
          this.$toast.error("Salvo com sucesso!", {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    sumField(key) {
      if (key === "Mês") return;
      // sum data in give key (property)
      return stringToBrMoney(
        this.tabFaturamento.reduce((a, b) => a + (b[`C-${key}`] * 1 || 0), 0),
      );
    },
    mediaField(key) {
      if (key === "Mês") return;
      // sum data in give key (property)
      return stringToBrMoney(
        stringToFloat(
          this.tabFaturamento.reduce(
            (a, b) => a + (b[`C-${key}`] / 12 || 0),
            0,
          ),
        ),
      );
    },
    async calculaFormula() {
      await this.buscaIndice();
      this.espelhoCalc = [];

      console.log(this.valoresBase);

      let reajusteAv = this.reajusteAvulso
        ? parseFloat(this.reajusteAvulso.replace(".", "").replace(",", "."))
        : 0;

      let valorUnidade = this.valoresBase.filter(e => e.ZXE_TPCALC === "UN")[0]
        ? this.valoresBase.filter(e => e.ZXE_TPCALC === "UN")
        : [{ ZXE_META: 0 }];
      let valorAssistente = this.valoresBase.filter(
        e => e.ZXE_TPCALC === "AS",
      )[0]
        ? this.valoresBase.filter(e => e.ZXE_TPCALC === "AS")
        : [{ ZXE_META: 0 }];

      this.espelhoCalc.push(stringToBrMoney(valorUnidade[0].ZXE_META));
      this.espelhoCalc.push(stringToBrMoney(valorAssistente[0].ZXE_META));
      this.espelhoCalc.push(stringToBrMoney(reajusteAv));

      const total =
        valorUnidade[0].ZXE_META - valorAssistente[0].ZXE_META + reajusteAv;

      this.espelhoCalc.push(stringToBrMoney(total));
      const porc = this.fonoEscolhido.porc;
      this.espelhoCalc.push(porc);
      let meta = 0;
      if (this.dadosCalculo[0].itens[0].ZXD_DIVIDE === "S") {
        meta = stringToBrMoney(Number(((total * porc) / 100 / 1).toFixed(2)));
      } else {
        meta = stringToBrMoney(Number(((total * porc) / 100).toFixed(2)));
      }

      this.espelhoCalc.push(meta);
      this.espelhoCalc.push(this.fonoEscolhido.divisor);

      this.calcCompleto = true;
    },
    async buscaIndice() {
      this.metas = [];
      this.resultTrimestre = [];
      //Consultar a tabela de espelho de calculo
      this.metas = [
        { mes: "Janeiro", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Fevereiro", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Março", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Abril", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Maio", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Junho", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Julho", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Agosto", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Setembro", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Outubro", meta: 0, percent: 0, trimestre: 4 },
        { mes: "Novembro", meta: 0, percent: 0, trimestre: 4 },
        { mes: "Dezembro", meta: 0, percent: 0, trimestre: 4 },
      ];
      this.resultTrimestre = [
        { title: "1 Trimestre", valor: 0, percent: 0, trimestre: 1 },
        { title: "2 Trimestre", valor: 0, percent: 0, trimestre: 2 },
        { title: "3 Trimestre", valor: 0, percent: 0, trimestre: 3 },
        { title: "4 Trimestre", valor: 0, percent: 0, trimestre: 4 },
      ];
      this.indiceBanco = [];
      this.travaCampos = false;
      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      const dadosCalc = this.dadosCalculo[0].itens[0];

      let anoTrimestre = "";

      if (Number(this.trimestre[0].value.split("-")[0]) === 0) {
        anoTrimestre = `${this.anoEscolhido}01`;
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 3) {
        anoTrimestre = `${this.anoEscolhido}02`;
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 6) {
        anoTrimestre = `${this.anoEscolhido}03`;
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 9) {
        anoTrimestre = `${this.anoEscolhido}04`;
      }

      let dados = {
        FIELDS: "*",
        WHERE: `ZXE_TPCALC='FN' AND ZXE_TIPO='${dadosCalc.ZXD_TIPO}' AND ZXE_ANOTRI='${anoTrimestre}' AND ZXE_LOJA='${dadosCalc.ZXD_LOJA}' AND ZXE_HRTRAB='${this.fonoEscolhido.id}'`,
        TABELA: "ZXE",
      };

      const indiceBanco = await axios.post(url, dados);

      if (indiceBanco.data.length > 0) {
        this.indiceBanco = indiceBanco.data;
        this.indRejuste = indiceBanco.data[0].ZXE_INDICE.toString().replace(
          ".",
          ",",
        );
        this.reajusteAvulso = indiceBanco.data[0].ZXE_VLREAJ.toLocaleString(
          "pt-BR",
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        );
        // this.calculaFormula();
        this.travaCampos = true;
      }

      dados = {
        FIELDS: "ZXE_ANOTRI,ZXE_INDICE,ZXE_VLREAJ,ZXE_META",
        WHERE: `ZXE_TPCALC='FN' AND LEFT(ZXE_ANOTRI,4)='${this.anoEscolhido}' AND ZXE_LOJA='${dadosCalc.ZXD_LOJA}' AND ZXE_HRTRAB='${this.fonoEscolhido.id}' `,
        TABELA: "ZXE",
      };

      const metasBanco = await axios.post(url, dados);

      if (metasBanco.data.length > 0) {
        metasBanco.data.map(e => {
          if (Number(e.ZXE_ANOTRI.substr(4, 2)) === 1) {
            this.metas
              .filter(m => m.trimestre === 1)
              .map(m => {
                m.meta = stringToBrMoney(e.ZXE_META);
                m.metaCurr = Number(e.ZXE_META.toFixed(2));
              });
            this.resultTrimestre
              .filter(t => t.trimestre === 1)
              .map(t => {
                t.valor = stringToBrMoney(e.ZXE_META * 3);
                t.valorCurr = Number((e.ZXE_META * 3).toFixed(2));
              });
          }
          if (Number(e.ZXE_ANOTRI.substr(4, 2)) === 2) {
            this.metas
              .filter(m => m.trimestre === 2)
              .map(m => {
                m.meta = stringToBrMoney(e.ZXE_META);
                m.metaCurr = Number(e.ZXE_META.toFixed(2));
              });
            this.resultTrimestre
              .filter(t => t.trimestre === 2)
              .map(t => {
                t.valor = stringToBrMoney(e.ZXE_META * 3);
                t.valorCurr = Number((e.ZXE_META * 3).toFixed(2));
              });
          }
          if (Number(e.ZXE_ANOTRI.substr(4, 2)) === 3) {
            this.metas
              .filter(m => m.trimestre === 3)
              .map(m => {
                m.meta = stringToBrMoney(e.ZXE_META);
                m.metaCurr = Number(e.ZXE_META.toFixed(2));
              });
            this.resultTrimestre
              .filter(t => t.trimestre === 3)
              .map(t => {
                t.valor = stringToBrMoney(e.ZXE_META * 3);
                t.valorCurr = Number((e.ZXE_META * 3).toFixed(2));
              });
          }
          if (Number(e.ZXE_ANOTRI.substr(4, 2)) === 4) {
            this.metas
              .filter(m => m.trimestre === 4)
              .map(m => {
                m.meta = stringToBrMoney(e.ZXE_META);
                m.metaCurr = Number(e.ZXE_META.toFixed(2));
              });
            this.resultTrimestre
              .filter(t => t.trimestre === 4)
              .map(t => {
                t.valor = stringToBrMoney(e.ZXE_META * 3);
                t.valorCurr = Number((e.ZXE_META * 3).toFixed(2));
              });
          }
        });
      }

      const anoAnterior = sub(new Date(this.anoEscolhido, 0, 1), { years: 1 });

      let inicioAnoAnterior = new Date(anoAnterior.getFullYear(), 0, 1);

      let trimestreCont = 1;
      let contMes = 0;
      let valorTrimAnterior = 0;

      for (let cont = 0; cont <= 12; cont++) {
        const mes = add(inicioAnoAnterior, { months: cont }).getMonth();
        const ano = add(inicioAnoAnterior, {
          months: cont,
        }).getFullYear();

        if (contMes === 3 && trimestreCont === 1) {
          const valor = this.resultTrimestre.filter(
            t => t.trimestre === trimestreCont,
          )[0].valorCurr;

          if (valor > 0) {
            this.resultTrimestre
              .filter(t => t.trimestre === trimestreCont)
              .map(
                t =>
                  (t.percent = (
                    (valor * 100) / valorTrimAnterior -
                    100
                  ).toFixed(2)),
              );
          }
          contMes = 0;
          valorTrimAnterior = 0;
          trimestreCont++;
        }

        if (contMes === 3 && trimestreCont === 2) {
          const valor = this.resultTrimestre.filter(
            t => t.trimestre === trimestreCont,
          )[0].valorCurr;

          if (valor > 0) {
            this.resultTrimestre
              .filter(t => t.trimestre === trimestreCont)
              .map(
                t =>
                  (t.percent = (
                    (valor * 100) / valorTrimAnterior -
                    100
                  ).toFixed(2)),
              );
          }
          contMes = 0;
          valorTrimAnterior = 0;
          trimestreCont++;
        }

        if (contMes === 3 && trimestreCont === 3) {
          const valor = this.resultTrimestre.filter(
            t => t.trimestre === trimestreCont,
          )[0].valorCurr;

          if (valor > 0) {
            this.resultTrimestre
              .filter(t => t.trimestre === trimestreCont)
              .map(
                t =>
                  (t.percent = (
                    (valor * 100) / valorTrimAnterior -
                    100
                  ).toFixed(2)),
              );
          }
          contMes = 0;
          valorTrimAnterior = 0;
          trimestreCont++;
        }

        if (contMes === 3 && trimestreCont === 4) {
          const valor = this.resultTrimestre.filter(
            t => t.trimestre === trimestreCont,
          )[0].valorCurr;

          if (valor > 0) {
            this.resultTrimestre
              .filter(t => t.trimestre === trimestreCont)
              .map(
                t =>
                  (t.percent = (
                    (valor * 100) / valorTrimAnterior -
                    100
                  ).toFixed(2)),
              );
          }
          contMes = 0;
          valorTrimAnterior = 0;
          trimestreCont++;
        }

        if (cont <= 11) {
          const valor = this.metas.filter(m => m.trimestre === trimestreCont)[0]
            .metaCurr;

          if (valor > 0) {
            this.metas.filter(m => m.trimestre === trimestreCont)[
              contMes
            ].percent = Number(
              (
                (valor * 100) /
                  this.tabFaturamento.find(e => Number(e.valor) === mes)[
                    `C-${ano}`
                  ][0] -
                100
              ).toFixed(2),
            );

            valorTrimAnterior += this.tabFaturamento.find(
              e => Number(e.valor) === mes,
            )[`C-${ano}`][0];
          }
        }

        contMes++;
      }
    },
    async salvaMeta() {
      if (!this.espelhoCalc[5]) return;
      let anoTrimestre = "";

      if (Number(this.trimestre[0].value.split("-")[0]) === 0) {
        anoTrimestre = `${this.anoEscolhido}01`;
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 3) {
        anoTrimestre = `${this.anoEscolhido}02`;
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 6) {
        anoTrimestre = `${this.anoEscolhido}03`;
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 9) {
        anoTrimestre = `${this.anoEscolhido}04`;
      }

      const dadosInsert = {
        ZXE_TIPO: this.dadosCalculo
          .map(e => e.itens)
          .map(e => e[0].ZXD_TIPO)[0],
        ZXE_TPCALC: "FN",
        ZXE_ANOTRI: anoTrimestre,
        ZXE_INDICE: 0,
        ZXE_VLREAJ: this.reajusteAvulso
          ? parseFloat(this.reajusteAvulso.replace(".", "").replace(",", "."))
          : 0,
        ZXE_LOJA: this.dadosCalculo
          .map(e => e.itens)
          .map(e => e[0].ZXD_LOJA)[0],
        ZXE_META: parseFloat(
          this.espelhoCalc[5]
            .replace("R$", "")
            .replaceAll(".", "")
            .replace(",", "."),
        ),
        ZXE_HRTRAB: this.fonoEscolhido.id,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/metas`;

      await axios
        .post(url, dadosInsert)
        .then(res => {
          this.$toast.success(res.data, {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.dialogSalvarMeta = false;
          this.calculaFormula();
        })
        .catch(err => {
          console.log(err.response);
          this.$toast.error("Aconteceu algum erro!", {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    async buscaFonos() {
      this.fonoEscolhido = null;
      this.dadosFonos = [];
      const idCadastro = this.dadosCalculo[0].itens[0].ZXD_ID;

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      let trimestre = "";

      if (Number(this.trimestre[0].value.split("-")[0]) === 0) {
        trimestre = "1";
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 3) {
        trimestre = "2";
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 6) {
        trimestre = "3";
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 9) {
        trimestre = "4";
      }

      let dados = {
        FIELDS: "",
        WHERE: `ZXF_IDXD='${idCadastro}' AND ZXF_GRMETA='S'`,
        TABELA: "ZXF",
      };

      const dadosFono = await axios.post(url, dados);

      let horasTrab = [];

      if (dadosFono.data[0].ZXF_TRIMES === "*") {
        horasTrab = [
          ...new Set(
            dadosFono.data
              .filter(e => e.ZXF_TIPOPE === "F")
              .map(e => e.ZXF_HRTRAB),
          ),
        ];

        this.dadosFonos = horasTrab.map(e => {
          return {
            title: `Meta - ${e.trim()}HS`,
            porc: 100,
            divisor: dadosFono.data.filter(
              f => f.ZXF_HRTRAB === e && f.ZXF_TIPOPE === "F",
            ).length,
            id: e,
          };
        });
      } else {
        horasTrab = [
          ...new Set(
            dadosFono.data
              .filter(
                e => e.ZXF_TRIMES === trimestre && e.ZXF_HRTRAB.trim() !== "",
              )
              .map(e => e.ZXF_HRTRAB.trim()),
          ),
        ];
        this.dadosFonos = horasTrab.map(e => {
          return {
            title: `Meta - ${e.trim()}HS`,
            porc: dadosFono.data
              .filter(
                f =>
                  f.ZXF_HRTRAB.trim() === e &&
                  f.ZXF_TIPOPE === "F" &&
                  f.ZXF_TRIMES === trimestre,
              )
              .map(e => e.ZXF_PORCFA)[0]
              ? dadosFono.data
                  .filter(
                    f =>
                      f.ZXF_HRTRAB.trim() === e &&
                      f.ZXF_TIPOPE === "F" &&
                      f.ZXF_TRIMES === trimestre,
                  )
                  .map(e => e.ZXF_PORCFA)[0]
              : 100,
            divisor: dadosFono.data.filter(
              f =>
                f.ZXF_HRTRAB.trim() === e &&
                f.ZXF_TIPOPE === "F" &&
                f.ZXF_TRIMES === trimestre,
            ).length,
            id: e,
          };
        });
      }

      if (this.dadosFonos.length <= 0) {
        this.$toast.error("Não achei os dados dos fonos para cálculo!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.$store.commit("setLimpaCalc");
        return;
      }

      this.fonoEscolhido = this.dadosFonos[0];

      this.buscaIndice();
      this.calculaFormula();
    },
    async buscaValoresUNASS() {
      const dadosCalc = this.dadosCalculo[0].itens[0];

      let anoTrimestre = "";

      if (Number(this.trimestre[0].value.split("-")[0]) === 0) {
        anoTrimestre = `${this.anoEscolhido}01`;
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 3) {
        anoTrimestre = `${this.anoEscolhido}02`;
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 6) {
        anoTrimestre = `${this.anoEscolhido}03`;
      }
      if (Number(this.trimestre[0].value.split("-")[0]) === 9) {
        anoTrimestre = `${this.anoEscolhido}04`;
      }

      const dados = {
        FIELDS: "",
        WHERE: `ZXE_LOJA='${dadosCalc.ZXD_LOJA}' AND ZXE_ANOTRI='${anoTrimestre}' AND ZXE_TPCALC IN ('UN','AS')`,
        TABELA: "ZXE",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      const dadosBase = await axios.post(url, dados);

      if (dadosBase.data.length <= 0) {
        this.$toast.error(
          "Salve as metas da loja e ou assistentes antes.Não pode fazer o cálculo!",
          {
            position: "bottom-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          },
        );
        return;
      }

      this.valoresBase = dadosBase.data;

      this.buscaFonos();
    },
  },
  beforeMount() {
    this.buscaValoresUNASS();

    // this.buscaIndice();
  },
};
</script>
<style>
.v-data-table.tbmetasOpimed tbody tr td {
  font-size: 0.8rem !important;
  height: 25px !important;
  min-width: 130px;
}

.v-input.inputCalcEspelho {
  background-color: grey !important;
}
.v-input.inputCalcEspelho .v-label {
  margin: 5px;
  color: white;
}
.v-input.inputCalcEspelho input {
  color: white;
}

/* .v-data-table.tbmetasOpimed thead tr th {
  background-color: blue !important;
}
.v-data-table.tbmetasOpimed thead tr th span {
  color: white;
} */
</style>
